export const homeObjOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Follow the money',
  headLine: 'Find out More About Bitcoin Addresses',
  description: "Just type in an address and find out other addresses controled by the same entity and what communities it's linked to.",
  buttonLink: '/search_address',
  buttonLabel: 'Search Address',
  imgStart: '',
  img: 'images/search.svg',
  alt: 'individual report'
};


export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Community Insights',
  headLine: 'Explore Groups operating within Bitcoin',
  description: 'Look behind the scenes at how groups of interest are structured and who their key players are.',
  buttonLink: '/search_group',
  buttonLabel: 'Search Group',
  imgStart: 'start',
  img: 'images/svg-2.svg',
  alt: 'group report'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'The big picture',
  headLine: 'Visualise the Bitcoin Transaction Graph Changing',
  description: 'Get an overview of the main communities using Bitcoin and how they interact with each other.',
  buttonLink: '/search_full',
  buttonLabel: 'Full View',
  imgStart: '',
  img: 'images/bg-image.png',
  alt: 'full graph'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Increase Awareness',
  headLine: 'Help Identify an Address',
  description: 'Help the community by uncovering the identity of an address or transaction. Report an address and provide proof to be rewarded!',
  buttonLink: '/report',
  buttonLabel: 'Report',
  imgStart: '',
  img: 'images/report.svg',
  alt: 'report image'
};
