import React from 'react';
import { Button } from './Button';
import { ForceGraph } from "./network/forceGraph";
import './GraphSection.css';
import pdf from '../reports/group_example.pdf'






function GraphSection({ lightBg, topLine, lightText, lightTextDesc, headLine, description, buttonLabel, imgStart, linksData, nodesData}) {

  const nodeHoverTooltip = React.useCallback((node) => {
    return `<div>
      <b>${node.id}</b>
    </div>`;
  }, []);

  return(
    <>
    <div className={lightBg ? 'home__hero-section' :
    'home__hero-section darkBg'}>
      <div className="container">
        <div className="row home__hero-row"
        style={{display:'flex', flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'}}>
          <div className="col">
            <div className="home__hero-text-wrapper">
              <div className="top-line">{topLine}</div>
              <h1 className={lightText ? 'heading' : 'heading dark'}>{headLine}</h1>
              <p className={lightTextDesc ? 'home__hero-subtitle' : 'home__hero-subtitle dark'}>{description}</p>
              <a href={pdf} target="_blank" rel="noreferrer">
                <Button buttonSize='btn--wide' buttonColor='blue'>
                  {buttonLabel}
                </Button>
              </a>
            </div>
          </div>
          <div className="col">
            <div className="home__hero-img-wrapper">
              <ForceGraph linksData={linksData} nodesData={nodesData} nodeHoverTooltip={nodeHoverTooltip} />
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );

}

export default GraphSection
