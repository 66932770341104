import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import {
  FaEnvelope,
  FaGithub,
  FaLinkedin
} from 'react-icons/fa';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          We are constantly working to improve this website.
        </p>
        <p className='footer-subscription-text'>
          Keep an eye out for updates!
        </p>
      </section>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              <img src='images/fcl_logo.svg' alt='logo' className="logo-img"></img>
              FindCryptoLinks
            </Link>
          </div>
          <small className='website-rights'>FindCryptoLinks © 2022</small>
          <div className='social-icons'>
            <a 
            target="_blank" 
            href='mailto:findcryptolinks@gmail.com' 
            className='social-icon-link'>
              <FaEnvelope />
            </a>
            <a 
            target="_blank" 
            href='https://github.com/diego-lda'
            className='social-icon-link'>
              <FaGithub />
            </a>
            <a 
            target="_blank" 
            href='https://www.linkedin.com/in/dlaradeandres/'
            className='social-icon-link'>
              <FaLinkedin />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;