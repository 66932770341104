import React from 'react';
import HeroSection from '../../HeroSection';
import { graphObjOne, graphObjTwo, graphObjThree } from './Data';
import Options from '../../Options';
import GraphSectionA from '../../GraphSectionA';
import GraphSectionG from '../../GraphSectionG';
import GraphSectionFull from '../../GraphSectionFull';
import add_data from '../../../data/example_address.json';
import com_data from '../../../data/example_comm.json';
import data from '../../../data/data.json';



function Examples() {
  return(
    <>
      <GraphSectionA {...graphObjOne} linksData={add_data.links} nodesData={add_data.nodes} />
      <GraphSectionG {...graphObjTwo} linksData={com_data.links} nodesData={com_data.nodes} />
      <GraphSectionFull {...graphObjThree} linksData={data.links} nodesData={data.nodes} />
      <Options/>
    </>
  );
}

export default Examples;
