import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Button } from './Button';
import './Navbar.css';
import { IconContext } from 'react-icons/lib';


function Navbar () {

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if(window.ineerWidth <= 960) {
      setButton(false)
    } else {
      setButton(true)
    }
  };


  return(
    <>
    <IconContext.Provider value={{color:'#fff'}}>
      <div className="navbar">
        <div className="navbar-container container">
          <Link to='/' className="navbar-logo" onClick={closeMobileMenu}>
            <img src='images/fcl_logo.svg' alt='logo' className="logo-img"></img>
            FindCryptoLinks
          </Link>
          <div className ="menu-icon" onClick ={handleClick}>
          {click ? <FaTimes/> : <FaBars/>}
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
           <li className="nav-item">
              <Link to='/' className="nav-links" onClick={closeMobileMenu}>
                Home
              </Link>
           </li>
           <li className="nav-item">
              <Link to='/examples' className="nav-links" onClick={closeMobileMenu}>
                Examples
              </Link>
           </li>
           <li className="nav-item">
              <Link to='/methodology' className="nav-links" onClick={closeMobileMenu}>
                Methodology
              </Link>
           </li>
           <li className="nav-item">
              <Link to='/report' className="nav-links" onClick={closeMobileMenu}>
                Report
              </Link>
           </li>
           <li className="nav-btn">
            {button ? (
              <Link to='/search' className="btn-link">
                <Button buttonStyle="btn--outline">
                  Search
                </Button>
              </Link>
            ): (
              <Link to='/search' className="btn-link" onClick={closeMobileMenu}>
                <Button buttonStyle="btn--outline"
                        buttonSize="btn--mobile">
                  Search
                </Button>
              </Link>
            )}
           </li>
          </ul>
        </div>
        </div>
      </IconContext.Provider>
    </>
  )
}

export default Navbar
