import React from 'react';
import HeroSection from '../../HeroSection';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';
import Options from '../../Options';
import AddressForm from '../../RequestAdd';
import GroupForm from '../../RequestGrp';
import FullViewForm from '../../RequestFull';


function Search() {
  return(
    <>
      <Options/>
      <AddressForm/>
      <GroupForm/>
      <FullViewForm/>
    </>
  );
}

export default Search;
