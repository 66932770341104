import React from 'react';
import { repObj } from './Data';
import ReportSection from '../../ReportSection';


function Report() {
  return(
    <>
      <ReportSection {...repObj} />
    </>
  );
}

export default Report;
