import React from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Method.css';


function Method({ lightBg, lightText, lightTextDesc, topLine, headLine, description1, description2, img, alt, imgStart}) {

  return(
    <>
    <div className={lightBg ? 'method-section' :
    'method-section darkBg'}>
      <div className="container">
        <div className="row method-row"
        style={{display:'flex', flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'}}>
          <div className="col">
            <div className="method-text-wrapper">
              <div className="top-line">{topLine}</div>
              <h1 className={lightText ? 'heading' : 'heading dark'}>{headLine}</h1>
              <p className={lightTextDesc ? 'method-subtitle' : 'method-subtitle dark'}>{description1}</p>
              <p className={lightTextDesc ? 'method-subtitle' : 'method-subtitle dark'}>{description2}</p>
            </div>
          </div>
          <div className="col">
            <div className="method-img-wrapper">
              <img src={img} alt={alt} className="method-img"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );

}

export default Method
