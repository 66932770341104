import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import { IconContext } from 'react-icons/lib';
import { HiUserGroup, HiOutlineIdentification, HiOutlineGlobeAlt } from 'react-icons/hi';
import './Request.css';

function FullViewForm() {

  const emailRef = React.useRef()
  const interestDateRef = React.useRef()

  function sendFullRequest(e){
    e.preventDefault();
    
    let email = emailRef.current.value
    let interest_date = interestDateRef.current.value

    fetch(process.env.REACT_APP_APIGENERAL,{
      method: 'POST',
      mode: 'cors',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept": "application/json", 
      }, 
      body: new URLSearchParams({
        'email': email,
        'interest_date': interest_date
      })
    }).then(function(response) {
      if(response.status == 200) { //OK
        alert("Your request was sent successfully! Check your inbox in about an hour.");
        window.location.href = 'methodology';
      } else {
        alert("Something went wrong. Please, check the data");
      }
    });
  }

  return(
    <IconContext.Provider value={{color: '#fff', size: 64}}>
    <>
      <div className="request__section">
        <div className="request__wrapper">
          < HiOutlineGlobeAlt />
          <h1 className="request__heading">Full View Request Form</h1>
          <p className="request__text">
            Select the day you would like to view together with your email. You will receive a PDF note with the insights in about an hour.
          </p>
          <div className="request__container">
            <form onSubmit = {sendFullRequest} action="https://www.w3schools.com/">
              <input
                className='request-input'
                ref={emailRef}
                name='rq_email'
                type='email'
                placeholder='Your Email'
              />
              <input
                className='request-input'
                ref={interestDateRef}
                name='rq_date'
                type='date'
                placeholder='Date'
              />
              <input
                className='request__btn'
                name='rq_submit'
                type='submit'
                value='Submit Request'
              />
            </form>
          </div>
        </div>
      </div>
    </>
    </IconContext.Provider>
  )
}

export default FullViewForm;
