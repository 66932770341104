import React from 'react';
import { Link } from 'react-router-dom';
import { HiUserGroup, HiOutlineIdentification, HiOutlineGlobeAlt } from 'react-icons/hi';
import { Button } from './Button';
import { IconContext } from 'react-icons/lib';
import './Options.css';

function Options() {
  return(
    <IconContext.Provider value={{color: '#fff', size: 64}}>
    <>
      <div className="options__section">
        <div className="options__wrapper">
          <h1 className="options__heading">Search Options</h1>
          <div className="options__container">
            <Link to='/search_group' className="options__container-card">
              <div className="options__container-info-card">
                <div className="icon">
                  < HiUserGroup />
                </div>
                <h3>Community</h3>
                <h4>Track groups</h4>
                <p>Understand the herd</p>
                <ul className="options__container-features">
                  <li>Cluster Size</li>
                  <li>Members List</li>
                  <li>Type of Activity</li>
                  <li>Place within BTC</li>
                </ul>
                <Button buttonSize="btn--wide" buttonColor="primary" >Search Group</Button>
              </div>
            </Link>
            <Link to='/search_address' className="options__container-card">
              <div className="options__container-info-card">
                <div className="icon">
                  < HiOutlineIdentification />
                </div>
                <h3>Address</h3>
                <h4>Track individuals</h4>
                <p>See their connections</p>
                <ul className="options__container-features">
                  <li>Wallet membership</li>
                  <li>Connections</li>
                  <li>Community Membership</li>
                  <li>Place within BTC</li>
                </ul>
                <Button buttonSize="btn--wide" buttonColor="blue" >Search Address</Button>
              </div>
            </Link>
            <Link to='/search_full' className="options__container-card">
              <div className="options__container-info-card">
                <div className="icon">
                  < HiOutlineGlobeAlt />
                </div>
                <h3>General</h3>
                <h4>Global View</h4>
                <p>Understand BTC</p>
                <ul className="options__container-features">
                  <li>Graph Measures</li>
                  <li>Activity Breakdown</li>
                  <li>Key Communities</li>
                  <li>Key Wallets</li>
                </ul>
                <Button buttonSize="btn--wide" buttonColor="primary" >Full View</Button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
    </IconContext.Provider>
  )
}

export default Options;
