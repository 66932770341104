export const methObjOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'FROM ADDRESS TO WALLET',
  headLine: 'Identifying which Addresses Belong to the same Agent.',
  description1: 'Bitcoin addresses are pseudonymos, which means it is hard to link them to individuals or organisations. On top, one person could control many addresses.',
  description2: 'Using a Union-Find algorithm, we can have a guess at what addresses ore controled by the same agent and form a "wallet". Combining this with known addresses from sources such as walletexplorer.com we get a better picture.',
  imgStart: 'start',
  img: 'images/collect.svg',
  alt: 'community cuts'
};

export const methObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'FINDING CLUSTERS',
  headLine: 'Powered by Community Detection Algorithms',
  description1: 'Using all of the transactions that are performed using Bitcoin a graph is generated, where wallets become nodes connected by transactions. On this graph, we run several community detection algorithms, including Louvain or spectral splitting.',
  description2: 'This enables the identification of wallets that are close to each other. Using this and the address tags, we can determine what type of community we have. We can also keep track of the dynamics of this community across time.',
  imgStart: '',
  img: 'images/academic.svg',
  alt: 'community cuts'
};
