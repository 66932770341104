import React from 'react';
import HeroSection from '../../HeroSection';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';
import Options from '../../Options';
import GroupForm from '../../RequestGrp';


function Search_Grp() {
  return(
    <>
      <GroupForm/>
      <Options/>
    </>
  );
}

export default Search_Grp;
