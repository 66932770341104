import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/pages/HomePage/Home';
import Examples from './components/pages/Examples/Examples';
import Methodology from './components/pages/Methodology/Methodology';
import Search from './components/pages/Search/Search';
import Search_Full from './components/pages/Search_Full/Search_Full';
import Search_Grp from './components/pages/Search_Grp/Search_Grp';
import Search_Add from './components/pages/Search_Add/Search_Add';
import Report from './components/pages/Report/Report';
import Footer from './components/pages/Footer/Footer';
import { ForceGraph } from "./components/network/forceGraph";
import ScrollToTop from './components/ScrollToTop';


function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/examples' element={<Examples/>} />
        <Route path='/methodology' element={<Methodology/>} />
        <Route path='/report' element={<Report/>} />
        <Route path='/search' element={<Search/>} />
        <Route path='/search_full' element={<Search_Full/>} />
        <Route path='/search_group' element={<Search_Grp/>} />
        <Route path='/search_address' element={<Search_Add/>} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
