import React from 'react';
import HeroSection from '../../HeroSection';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';
import Options from '../../Options';
import FullViewForm from '../../RequestFull';


function Search_Full() {
  return(
    <>
      <FullViewForm/>
      <Options/>
    </>
  );
}

export default Search_Full;
