import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import { IconContext } from 'react-icons/lib';
import { HiUserGroup, HiOutlineIdentification, HiOutlineGlobeAlt } from 'react-icons/hi';
import './Request.css';


function GroupForm() {

  const emailRef = React.useRef()
  const communityRef = React.useRef()
  const interestDateRef = React.useRef()

  function sendGroupRequest(e){
    e.preventDefault();
    
    let email = emailRef.current.value
    let community = communityRef.current.value
    let interest_date = interestDateRef.current.value

    fetch(process.env.REACT_APP_APICOMM,{
      method: 'POST',
      mode: 'cors',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept": "application/json", 
      }, 
      body: new URLSearchParams({
        'email': email,
        'interest_date': interest_date,
        'community': community
      })
    }).then(function(response) {
      if(response.status == 200) { //OK
        alert("Your request was sent successfully! Check your inbox in about an hour.");
        window.location.href = 'methodology';
      } else {
        alert("Something went wrong. Please, check the data");
      }
    });

  }



  return(
    <IconContext.Provider value={{color: '#fff', size: 64}}>
    <>
      <div className="request__section">
        <div className="request__wrapper">
          < HiUserGroup />
          <h1 className="request__heading">Group Request Form</h1>
          <p className="request__text">
            Select the BTC community and day you would like to view together with your email. 
            Communities are named by their rank, numbers from 1-100. 
            You will receive a PDF note with the insights in about an hour.
          </p>
          <div className="request__container">
            <form onSubmit = {sendGroupRequest}>
              <input
                className='request-input'
                ref={emailRef}
                name='rq_email'
                type='email'
                placeholder='Your Email'
              />
              <input
                className='request-input'
                ref={communityRef}
                name='rq_grp'
                type='text'
                placeholder='Community (1-100)'
              />
              <input
                className='request-input'
                ref={interestDateRef}
                name='rq_date'
                type='date'
                placeholder='Date'
              />
              <input
                className='request__btn'
                name='rq_submit'
                type='submit'
                value='Submit Request'
              />
            </form>
          </div>
        </div>
      </div>
    </>
    </IconContext.Provider>
  )
}

export default GroupForm;
