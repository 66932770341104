import React from 'react';
import HeroSection from '../../HeroSection';
import Method from '../../Method';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour, methObjOne, methObjTwo } from './Data';
import Options from '../../Options';



function Methodology() {
  return(
    <>
      <Method {...methObjOne} />
      <Method {...methObjTwo} />
      <Options/>
    </>
  );
}

export default Methodology;
