export const repObj = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Increase Awareness',
  headLine: 'Help Identify an Address',
  description: 'If you know the identity of an address, help keep the community safer by sending the form below alongside any evidence to findcryptolinks@gmail.com',
  buttonLabel: 'Report Form',
  imgStart: 'start',
  img: 'images/report.svg',
  alt: 'report image'
};