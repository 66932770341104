import React from 'react';
import HeroSection from '../../HeroSection';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';
import Options from '../../Options';
import AddressForm from '../../RequestAdd';


function Search_Add() {
  return(
    <>
      <AddressForm/>
      <Options/>
    </>
  );
}

export default Search_Add;
