export const graphObjOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Track Individuals',
  headLine: 'Visualise who they are Linked with.',
  description: 'For a given address, find out if it is part of a multi-address wallet. Also who they have transactions with and whay type of community they are connected to.',
  buttonLabel: 'Address PDF Note',
  imgStart: '',
};

export const graphObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Observe clusters',
  headLine: 'Reveal Community Structures.',
  description: 'Choose from the pre-identified communities or select one where your address of interest belongs to. Learn about key features of the community structure and about the main players.',
  buttonLabel: 'Group PDF Note',
  imgStart: 'start',
};

export const graphObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'General overview',
  headLine: 'Get a quick look at the Bitcoin Network.',
  description: 'For a given day, observe a general view of the Bitcoin transaction network. See how different communities interact with each other.',
  buttonLabel: 'Full View PDF Note',
  imgStart: '',
};
